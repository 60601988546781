<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-12 16:02:59
 * @Description: 消息通知
-->
<style lang="scss" scoped>
.page-notify {
  @include innerPage;
  @include pageTitle;
  @include defalutTable(calc(100% - 38px), 10px);
  @include pageFoot;
}
</style>

<template>
  <section class="page-notify">
    <div class="page-title">
      <h3>消息通知</h3>
      <el-button class="light" type="custom_primary" size="small" @click="$router.go(-1)">
        返回
      </el-button>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="tableData" ref="multipleTable" height="100%" @selection-change="handleSelectionChange">
          <el-table-column align="center" type="selection" width="140" :selectable="selectable" />
          <el-table-column align="center" prop="origin_name" label="通知方" min-width="9%" />
          <el-table-column align="center" prop="plsch_mes_title" label="标题" min-width="12%" />
          <el-table-column align="center" prop="create_time" label="时间" min-width="22%" />
          <el-table-column align="center" label="状态" min-width="8%">
            <template slot-scope="scope">
              <p class="table-status"
                :class="{ danger: scope.row.plsch_mes_status == 10, failed: scope.row.plsch_mes_status == 20 }">
                {{ scope.row.plsch_mes_status == 10 && '已读' || '未读' }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" min-width="13.5%">
            <template slot-scope="scope">
              <el-button type="custom_success" size="mini" plain
                @click="$router.push({ name: 'NOTIFY_DETAIL', params: { id: scope.row.plsch_mes_id } })">
                查看
              </el-button>
              <el-button type="custom_danger" @click="delMsg(scope.row.plsch_mes_id)" size="mini" plain>
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <div class="button-group">
          <el-button type="custom_success" @click="checkRead" size="medium">
            <span class="iconfont">&#xe61e;</span>
            <span>标记已读</span>
          </el-button>
          <el-button type="custom_primary" @click="allRead" size="medium">
            <span class="iconfont">&#xe61b;</span>
            <span>全部已读</span>
          </el-button>
        </div>
        <customPagination :current='form.pageIndex' :total="total" @pageChange="flippingPage" />
      </div>
    </div>
  </section>
</template>

<script>
import { getMessageList, markAllStatus, markStatus, $deleteMsg } from "@api/home";
import { mapActions } from "vuex"
import customPagination from "@comp/customPagination"
export default {
  name: "home_notify",
  components: {
    customPagination
  },
  data() {
    return {
      form: {
        pageindex: 1,
        pagesize: 10,
      },//分页
      total: 0,
      tableData: [],
      currentPage: 1,//当前页
      pageCurrent: "",//转跳页
      multipleSelection: [],//勾选的数据
      readLoad: false,
    };
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions('user', ['getMsgReadStatus']),
    /** 初始化数据 */
    async init() {
      this.getMsgReadStatus();
      const res = await getMessageList(this.form);
      if (res.status == 200) {
        this.tableData = res.data.data;
        this.total = res.data.allcount;
        this.currentPage = res.data.pageindex;
      }
    },
    /** 判断表格多选框是否可用 */
    selectable(row) {
      if (row.plsch_mes_status == 10) return false;
      else return true;
    },
    /** 勾选数据 */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 切换页码 */
    flippingPage(val) {
      this.form.pageindex = val;
      this.init();
    },
    /** 标记已读 */
    async checkRead() {
      let ids = [];
      this.multipleSelection.map(item => ids.push(item.plsch_mes_id));
      if (this.$isEmpty(ids)) return this.$message.warning('请选择需要操作的数据')
      this.$msgbox({
        title: '提示',
        message: '确认将所选消息通知标记为已读？',
        type: 'info',
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: 'el-button--custom_info',
        confirmButtonClass: 'el-button--custom_primary',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await markStatus({ ids });
        if (res) {
          this.$message({
            type: 'success',
            duration: 1500,
            message: '标记成功'
          });
          this.init();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1500,
          message: '操作已取消'
        })
      });
    },
    /** 全部标记已读 */
    allRead() {
      this.$msgbox({
        title: '提示',
        message: '确认将所有消息通知标记为已读？',
        type: 'info',
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: 'el-button--custom_info',
        confirmButtonClass: 'el-button--custom_primary',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await markAllStatus();
        if (res) {
          this.$message({
            type: 'success',
            duration: 1500,
            message: '全部标记成功'
          });
          this.init();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1500,
          message: '操作已取消'
        })
      });
    },
    /**
     * 删除消息
     * @param {number} id 消息id
     */
    delMsg(id) {
      this.$msgbox({
        title: '删除',
        message: '确认删除此条消息通知？',
        type: 'warning',
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: 'el-button--custom_info',
        confirmButtonClass: 'el-button--custom_primary',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async () => {
        const res = await $deleteMsg(id);
        if (res) {
          this.$message({
            type: 'success',
            duration: 1500,
            message: '消息已删除！'
          });
          this.total = this.total - 1 < 0 && 0 || this.total - 1;
          if (this.tableData.length == 1 && this.form.pageindex != 1) {
            this.form.pageindex = this.form.pageindex - 1;
          }
          this.init();
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          duration: 1500,
          message: '操作已取消'
        })
      });
    }
  },
};
</script>
